header {
  padding: 12px 0;
  position: relative;
  z-index: 1;
  background: url(/src/assets/img/home/stone-bg.png) no-repeat;
  background-size: cover;
}

header::before {
  background: rgba(0, 0, 0, 0.75);
  z-index: 2;
}

.headerContainer {
  position: relative;
  z-index: 3;
  padding: 10px 0;
}

.headerContainer .logo img {
  width: 182px;
  height: auto;
  position: relative;
  z-index: 3;
  /*transform: translateX(-56%);*/
}

.headerContainer nav .hoverEffect {
  padding: 20px;
}

.headerContainer nav .hoverEffect:hover a {
  opacity: 1;
}

.headerContainer nav a {
  font-family: albertus-nova, sans-serif;
  font-size: 16px;
  opacity: 0.5;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 18;
}

.ruggedLine {
  width: 100%;
  height: 45px;
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: -27px;
  background: url(../img/home/ruggedLine.png) no-repeat;
  background-size: cover;
}

main {
  position: relative;
  z-index: 0;
}
/*.headerContainer nav a span {
  background: unset;
}

.headerContainer nav a:hover {
  background: linear-gradient(
    180deg,
    rgba(243, 212, 120, 0) 0%,
    #9c7c1b 72.98%
  );
  opacity: 0.3;
  filter: blur(15px);
}
*/

.homeSection {
  padding-bottom: 130px;
}

.sectionContainer {
  position: relative;
  z-index: 2;
}

.sectionContainer > h3 {
  font-family: work-sans, sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  color: var(--accent);
  letter-spacing: 8px;
  text-transform: uppercase;
  margin-bottom: 9px;
}

.sectionContainer > h2 {
  text-align: center;
  padding: 30px 0 40px 0;
}

.sectionContainer > h2 span {
  font-family: albertus-nova-bold, sans-serif;
  font-size: var(--section-title-size);
  color: var(--section-title-color);
  background: linear-gradient(180deg, #eaecef 0%, #b3b4b5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 3px;
  position: relative;
  text-transform: uppercase;
  padding-top: 2px;
}

.sectionContainer > h2 span::before,
.sectionContainer > h2 span::after {
  content: "";
  position: absolute;
  width: 36px;
  height: 36px;
  bottom: 50%;
  background: url(../img/home/titleDimond.png) no-repeat;
  background-size: cover;
}

.sectionContainer > h2 span::before {
  left: 0;
  transform: translate(-110%, 55%);
}

.sectionContainer > h2 span::after {
  right: 0;
  transform: translate(95%, 56%);
}

.sectionContainer > p {
  font-family: work-sans, sans-serif;
  text-align: center;
  font-size: 27px;
  letter-spacing: -0.8px;
  color: var(--card-text);
  position: relative;
  z-index: 1;
}
.privacy-policy-div {
  position: fixed;
  bottom: 0;
  top: 88%;
  left: 24%;
}
.homeSection.mainSection {
  background: linear-gradient(180deg, #191818 0%, rgba(25, 24, 24, 0) 100%);
  height: 776px;
  position: relative;
  z-index: 2;
  display: flex;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.45));
}

.homeSection.mainSection::before {
  background: url(../img/home/headerBg.png) no-repeat;
  background-size: cover;
}

.homeSection .textComposition {
  width: 100%;
  margin: auto;
  z-index: 5;
  position: relative;
}

.further {
  position: fixed;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 18;
}

.further img {
  width: 76px;
  display: block;
  margin-bottom: -20px;
  animation: blink 2.2s infinite ease-in-out;
}

.further img:nth-child(1) {
  animation-delay: 0.2s;
}
.further img:nth-child(2) {
  animation-delay: 0.5s;
}
.further img:nth-child(3) {
  animation-delay: 0.8s;
}

.welcomePart img {
  width: 348px;
  height: 270px;
  margin-bottom: 21px;
}

.welcomePart {
  font-family: albertus-nova, sans-serif;
}

.welcomePart h1 {
  letter-spacing: 4px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 64px;
}

.welcomePart p {
  font-size: 20px;
  margin-top: 20px;
  padding-bottom: 60px;
  text-shadow: 1px 1px 7px rgba(0, 0, 0, 0.91);
  letter-spacing: 1px;
}

.mainSection .bgEffect {
  background: url(../img/home/headerBgEffect1.png) no-repeat;
  background-size: cover;
  z-index: 2;
  opacity: 0.4;
}

.mainSection .bgEffect2 {
  background: url(../img/home/headerBgEffect2.png) no-repeat;
  background-size: cover;
  z-index: 3;
  opacity: 1;
}

.mainSection,
.featureSection,
.introductonSection,
.mechanicSection,
.factionSection,
.worldSection,
.alliesSection,
.communitySection,
.wikiSection,
.aboutSection,
.deckSection,
footer {
  background: var(--mainBg);
  position: relative;
}

.featureSection a {
  display: none;
}

.featureSection::before {
  background: url(../img/home/featureSmoke.png) no-repeat;
  background-size: contain;
  background-position: bottom;
}

.featuresContainer > h2,
.communityContainer > h2 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.sliderCards {
  position: relative;
  z-index: 13;
  height: 629px;
  width: var(--sliderCard);
  background: url(../img/home/sliderCardGolden.png) no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 auto;
  transform: translateX(5%);
}

.underbelly {
  position: absolute;
  left: 10%;
  display: none;
  top: 50%;
  transform: translateY(-50%);
  height: 65%;
  z-index: 12;
}

.underbelly.reversed {
  right: 10%;
  display: none;
}

.communityContainer .sliderCards {
  transform: unset;
  /* width: calc(var(--sliderCard) * 0.0); */
  background: url(../img/home/sliderCardGolden.png) no-repeat;
  background-size: 100% 85%;
  background-position: center;
}

.sliderCardButton {
  width: 123px;
  height: 123px;
  bottom: 50%;
  background: url(../img/home/cardNext.svg) no-repeat;
  background-size: cover;
  cursor: pointer;
  z-index: 999;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.sliderCardButton.fancyArrow {
  position: absolute;
  width: 67px;
  top: 55%;
}

.sliderCardButton.fancyArrow.sliderCardButton-prev {
  background: url(../img/home/prev.svg) no-repeat;
  background-size: cover;
  left: 10%;
}

.sliderCardButton.fancyArrow.sliderCardButton-next {
  background: url(../img/home/next.svg) no-repeat;
  background-size: cover;
  right: 10%;
}

.sliderCardButton.fancyArrow.sliderCardButton-prev:active {
  background: url(../img/home/prevActive.svg) no-repeat;
  background-size: cover;
}

.sliderCardButton.fancyArrow.sliderCardButton-next:active {
  background: url(../img/home/nextActive.svg) no-repeat;
  background-size: cover;
}

.sliderCard {
  height: 100%;
  display: none;
  justify-content: center;
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

/* Keyframes for sliding out */
@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* Apply the keyframes to the card */

.sliderCard.selected,
.mech.flexCol.selected {
  display: flex;
}

.featureTemplate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.featureTemplate img {
  position: absolute;
  left: 0;
  width: calc(501px * 1.2);
  transform: translate(-42%, -2%);
  height: auto;
  z-index: 2;
}

.cardText {
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.cardText a {
  display: flex;
}
.featureTemplate .cardText a {
  display: none;
}
.cardText .volButton {
  margin-top: -20px;
}

.featureTemplate .cardText {
  padding-right: 0;
  width: 50%;
  margin: auto 10% auto auto;
}

.cardText {
  font-family: albertus-nova, sans-serif;
}

.cardText h3 {
  font-size: 42px;
  line-height: 50px;
}

.cardText p {
  font-family: work-sans, sans-serif;
  color: var(--card-text);
  line-height: 30px;
  margin: 35px 0 39px 0;
  letter-spacing: -0.8px;
  padding-right: 30px;
}

.cardText a,
span.learMore {
  text-transform: uppercase;
  color: var(--accent);
  letter-spacing: 1px;
  position: relative;
  z-index: 99;
}

span.learMore {
  cursor: pointer;
}

.introductonSection {
  z-index: 1;
}

.introductonSection::before {
  background: linear-gradient(transparent 0 10%, var(--mainBg) 40% 100%),
    url(#) no-repeat;
  background-size: contain;
  opacity: 0.8;
  z-index: -1;
}

.introductonSection.visible::before {
  background: linear-gradient(transparent 0 10%, var(--mainBg) 40% 100%),
    url(../img/home/introGhostBg.png) no-repeat;
  background-size: contain;
}

.introductonsContainer,
.mechanicsContainer {
  padding-top: 100px;
}

.introductionCard {
  margin-bottom: -50px;
}

.introductionCard img {
  width: 800px;
  transform: translateX(-10%) translateY(-12%);
}
.introductionCard.oddItem img {
  transform: translateX(10%) translateY(-12%);
}

.introductionCard-video {
  width: 800px;
}

.pictureWrapper {
  transform: translateX(-7%);
}

.pictureWrapper.pure:not(.ios)::after {
  background: unset;
}

.pictureWrapper:not(.ios)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: linear-gradient(
      to bottom,
      var(--mainBg) 0%,
      transparent 17% 65%,
      var(--mainBg) 97% 100%
    ),
    linear-gradient(
      to right,
      var(--mainBg) 0 2%,
      transparent 10% 65%,
      var(--mainBg) 100%
    ),
    transparent;
}

.oddItem .pictureWrapper {
  transform: translateX(7%);
}

.introductionCard-pictureEffect {
  transform: rotate(0deg) translate(2%, 0%) scale(0.8);
  width: 800px;
  height: 600px;
  background: /*linear-gradient(
      to right,
      rgba(230, 112, 45, 0.2) 0% 0.01%,
      transparent 2% 99%,
      rgba(230, 112, 45, 0.2) 99.99% 100%
    ),*/ url(../img/lists/intorductions/introEffect4.png);
  background-size: 100%;
  background-position: 0 50%;
  /*animation: fireEfferc 9s linear infinite;*/
}

@keyframes fireEfferc {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 115%;
  }
  100% {
    background-size: 100%;
  }
}

.mechanicSection::before {
  opacity: 1;
  z-index: 0;
}

.mechanicSection.visible::before {
  background: url(../img/home/mechanicsGhostBg.png) no-repeat;
  background-size: cover;
  background-position: 5% 100%;
}

.mechanicCards {
  margin-top: 86px;
  position: relative;
  z-index: 2;
}

.mechanicCard {
  width: 1150px;
  margin: auto;
}

.mechanicCard .mechanicCard-picture,
.mechanicCard ul {
  width: 100%;
}

.mechanicPoints.flexRow {
  align-items: flex-start;
  margin-top: 102px;
}

.mechanicPoint {
  width: 210px;
  text-align: center;
}

.mechanicPoint h2 {
  letter-spacing: 3px;
  font-size: 50px;
  color: var(--accent-light);
  position: relative;
  font-family: albertus-nova, sans-serif;
}

.mechanicPoint h2::after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #a56f2a 0%,
    rgba(165, 111, 42, 0) 100%
  );
  opacity: 0.8;
  filter: blur(22px);
  position: absolute;
  z-index: 2;
}

.mechanicPoint p {
  font-family: work-sans-medium, sans-serif;
  letter-spacing: -0.8px;
  padding-top: 18px;
}

.factionContainer {
  padding-top: 20px;
}

.factionSection::before {
  /*background: url(../img/home/factionsGhostBg.png) no-repeat;*/
  background-size: 100% 100%;
  background-position: 0 65%;
  opacity: 1;
  z-index: 0;
}

.factionSection.visible::before {
  background: url(../img/home/factionsGhostBg.png) no-repeat;
  background-size: 100% 100%;
  background-position: 0 65%;
}

.factionList {
  justify-content: center;
  padding: 100px 0 50px 0;
}

.circleWrapper {
  width: 137px;
  height: 137px;
  border-radius: 50%;
  background: rgba(25, 24, 24, 0.8);
  opacity: 0.9;
  display: flex;
  cursor: pointer;
  position: relative;
}

.circleWrapper:hover {
  border: 2px solid var(--accent);
}

.factionCard {
  margin-right: 87px;
}

.factionCard:last-of-type {
  margin-right: 0px;
}

.factionCard img {
  width: 112px;
  margin: auto;
  transform: translateY(10%);
}

.factionCard span {
  opacity: 0.5;
  margin-top: 10px;
}

.factionCard.selected span {
  opacity: 1;
}

.factionCard.selected .circleWrapper {
  border: 2px solid var(--accent);
}

.factionCard.selected .circleWrapper::before {
  content: "";
  position: absolute;
  z-index: -2;
  width: 93%;
  height: 93%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #fec400 0%,
    rgba(251, 199, 0, 0) 100%
  );
}

.unitCard {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.cardWrapper {
  position: relative;
  width: 315px;
  height: 486px;
}

.cardFrame {
  position: absolute;
  height: 112%;
  width: 107%;
  top: -23px;
  left: -11px;
  z-index: 12;
  background-position: center;
}

.unitCard img,
.unitCard video {
  position: absolute;
  height: 100%;
  width: 100%;
  top: -1px;
  left: 0;
  z-index: 0;
  filter: blur(15px);
  transition: all 0.3s ease-in;
  transform: translate(-1px, 2px);
}

.cardWrapper {
  background: url(../img/home/Illustration\ Hor.png);
  background-size: cover;
  z-index: 2;
}

/*.cardWrapper .failedLoad {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 92;
  left: 0;
  top: 0;
  filter: blur(0px);
  background-size: cover;
  background: url(../img/home/Illustration\ Hor.png);
  z-index: 0;
}*/

img.loadedImage,
video.loadedImage {
  background: unset;
  filter: blur(0);
}

.unitCard p {
  font-weight: 700;
  margin-top: 30px;
  text-align: center;
}

/*unitCarousel {
  overflow-x: auto;
}

.unitCarousel::-webkit-scrollbar {
  display: none;
}*/

.unitCarousel .flexRow {
  justify-content: center;
  cursor: grab;
}

.worldSection::before {
  background: linear-gradient(
      var(--mainBg) 0%,
      transparent 10% 80%,
      var(--mainBg) 95% 100%
    ),
    url(../img/home/worldGhostBg.png) no-repeat;
  background-size: cover;
  /*background-position: 5% 88%;*/
  opacity: 1;
  z-index: 0;
}

.worldSection.visible::before {
  background: linear-gradient(
      var(--mainBg) 0%,
      transparent 10% 80%,
      var(--mainBg) 95% 100%
    ),
    url(../img/home/worldGhostBg.png) no-repeat;
  background-size: cover;
}

.worldSection {
  padding-bottom: 80px;
}

.worldContainer {
  padding: 65px 0 0 0;
}

.wordCard-picture-wrapper {
  width: 65%;
}

.worldCard {
}
.worldCard .cardText {
}

/* .sectionContainer.alliesContainer > h2 span::before,
.sectionContainer.communityContainer > h2 span::before {
  top: 0;
  transform: translate(-105%, 101%);
} */

.sectionContainer.alliesContainer > h2 span::after {
  /* top: 0; */
  transform: translate(450%, 50%);
}

/* .sectionContainer.communityContainer > h2 span::after {
  top: 0;
  transform: translate(150%, 110%);
} */

.allyCards {
  justify-content: center;
  position: relative;
  z-index: 3;
}

.allyCardWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.allyCard {
  color: white;
  width: 330px;
  margin: 0 20px;
  padding: 0 10px;
  text-align: center;
  position: relative;
}

.allyCard h3 {
  text-transform: uppercase;
  letter-spacing: 4px;
}

.allyCard p {
  font-family: work-sans, sans-serif;
  margin-top: 50px;
  letter-spacing: -0.8px;
  color: var(--card-text);
}

.shineContainer {
  height: 350px;
  position: absolute;
  z-index: 32;
  width: 62%;
  top: 10%;
  margin: 0;
  background: transparent;
  overflow: hidden;
}

.alliesCradsWrapper {
  position: relative;
  height: 931px;
}
.alliesCradsWrapper::before {
  /*background: linear-gradient(transparent 0% 60%, var(--mainBg) 85% 100%),
    url(../img/home/alliesGhostBg.png) no-repeat;*/
  background-size: cover;
  background-position: 42% 100px;
  z-index: -1;
  opacity: 0.41;
}

.alliesCradsWrapper.visible::before {
  background: linear-gradient(transparent 0% 60%, var(--mainBg) 85% 100%),
    url(../img/home/alliesGhostBg.png) no-repeat;
  background-size: cover;
  background-position: 42% 100px;
}

.communitySection {
  padding-bottom: 60px;
}

.alliesSection {
  padding-bottom: 0;
}

.alliesSection .decorHor {
  position: absolute;

  width: 100%;
  height: 118px;
  left: 0;
  right: 0;
  top: 50px;
  margin: auto;
  background: linear-gradient(
      to right,
      var(--mainBg) 0 5%,
      transparent 20% 80%,
      var(--mainBg) 92% 100%
    ),
    url(../img/home/Illustration\ Hor.png) no-repeat;
  background-size: cover;
}

/* .testimonialTemplate.flexCol {
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 25;
  text-align: center;
  justify-content: space-between;
} */

.testimonialTemplate.flexCol {
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 25;
  text-align: center;
  /* justify-content: flex-start; */
  justify-content: unset;
  /* justify-content: space-between; */
}
.cardContainer {
  margin: auto;
}

.featureFrame {
  position: absolute;
  width: 60%;
  left: 5%;
  top: 19%;
  z-index: 1;
}

.featureFrame.reversed {
  left: unset;
  top: unset;
  right: 5.5%;
  bottom: 20.5%;
  transform: rotate(180deg);
}

.communitySection .featureFrame {
  top: 14%;
}

.communitySection .featureFrame.reversed {
  top: unset;
  bottom: 14%;
}

.communitySection .underbelly {
  height: 70%;
}

.communityContainer .cardContainer {
  display: flex;
  height: 69%;
}

.testimonialTemplate img {
  width: 250px;
  margin-top: 0px;
  min-height: 205px;
}

.flexCol.testimonialTemplate h3 {
  font-family: work-sans-bold, sans-serif;
  font-size: 18px;
  letter-spacing: 3px;
  padding: 5px 0 10px 0;
  color: var(--gold);
}

.testimonialTemplate span {
  font-family: work-sans, sans-serif;
  font-size: 18px;
  color: var(--gold);
  letter-spacing: 8px;
}

.testimonialTemplate p {
  font-family: work-sans, sans-serif;
  font-size: 27px;
  letter-spacing: -0.8px;
  color: var(--ligtGrey);
  margin: 10px 0 20px 0;
  line-height: 38px;
  width: 85%;
}

.testimonialTemplate a {
  color: var(--gold);
  font-size: 25px;
  margin: auto auto 0 auto;
}

.wikiSection .bookBg {
  background: url(../img/home/wikia.png) no-repeat;
  background-size: cover;
  position: relative;
  height: 650px;
  display: flex;
}

.wikiSection.visible .bookBg {
  background: url(../img/home/wikia.png) no-repeat;
  background-size: 100% 100%;
  height: 650px;
  display: flex;
}

.wikiSection .bookBg > .cardText {
  width: 30%;
  margin: auto 13% auto auto;
  transform: translateY(10%);
}

.wikiContainer > .container {
  margin-top: -40px;
}

.aboutSection {
  padding: 150px 0 0 0;
  height: 807px;
}

.aboutSection::before {
  background-size: cover;
  background-position: center;
}

.aboutSection.visible::before {
  background: linear-gradient(
      var(--mainBg) 5%,
      transparent 25% 80%,
      var(--mainBg) 95% 100%
    ),
    url(../img/home/AboutGhostBg.png) no-repeat;
  background-size: cover;
  background-position: center;
}

.backersCard {
  width: calc(250px * 0.95);
  height: calc(136px * 0.95);
  z-index: -22;
  background: rgba(0, 0, 0, 0.6);
  position: relative;
  display: flex;
  margin: 0 10px;
}

.backersCard img {
  margin: auto;
}

.backersCard::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../img/home/aboutFrame.svg) no-repeat;
  background-size: cover;
  z-index: 5;
}

.backersCards {
  padding-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 20px;
}

.sectionContainer.deckContainer > p {
  width: 850px;
  margin: auto;
  margin-bottom: 48px;
}

.deckSection {
  padding-bottom: 0;
}

.deckContainer {
  text-align: center;
  overflow: hidden;
}

.deckContainer h3,
.deckContainer h2,
.deckContainer p,
.deckContainer button {
  position: relative;
  z-index: 3;
}

.deckSection::before {
  background: /*radial-gradient(
        47.08% 47.08% at 49.89% 48.75%,
        #dd9b37 0%,
        rgba(115, 106, 74, 0) 97.01%
      )
      no-repeat,*/ url(../img/home/Smoke.png)
    no-repeat;
  background-size: 100% 80%;

  background-position: bottom;
}

.deckContainer::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.deckContainer.visible::after {
  background: url(../img/home/goldenFog.png) no-repeat;
  background-size: 80%;
  background-position: 50% 30%;
}

.deckContainer .deckCards {
  position: relative;
  height: 500px;
  width: 100%;
  margin-top: 180px;
  z-index: 2;
}

.animationContainer {
  flex-shrink: 0;
  position: absolute;
  width: 100%;
  height: 1200px;
  top: 90px;
  left: 50%;
  right: 0;
  margin: 0 auto;
  animation: spin 1000s infinite linear;
}

.animationContainer.paused {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.animationContainer.stop {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.deckCard {
  display: inline-block;
  width: 262px;
  height: 404px;
  position: absolute;
  transition: transform 0.7s ease;
  cursor: pointer;
}

.deckCard:first-of-type {
  /*margin-left: 0;*/
}

.deckCard img,
.deckCard video {
  width: calc(100% + 4px);
  height: 100%;
}

.popOut.deckCard img {
  width: calc(100% + 7px);
  transform: translateX(4px);
}

#portal .deckCard .cardWrapper > img {
  transform: translateX(0);
}

.popOut .cardFrame {
  left: -10px;
  top: -24px;
  width: 107%;
}

.popOut .cardSymbol {
  right: 9px;
}

.popOut .cardLevel {
  right: 3px;
}

.popOut .cardBasePower {
  left: 18px;
  top: 24px;
}

.appContainer.win .popOut .cardBasePower {
  top: 17px;
  left: 15px;
}

.appContainer.win .popOut .cardSymbol {
  top: 12px;
  right: 11px;
}

.appContainer.win .cardFrame {
  top: -22px;
}

.appContainer.win .popOut .spellSymbol {
  top: unset;
  bottom: -2px;
}

.appContainer.win .cardLevel {
  top: 33px;
}

.popOut .spellSymbol {
  transform: translateX(-42%);
}

#portal .deckCard .cardBasePower {
  left: 18px;
}

#portal.win .cardBasePower {
  left: 12.5px;
  top: 20.5px;
}

#portal.win .spellSymbol {
  bottom: -4.5px;
}

.unitCard .cardWrapper > img {
  width: calc(100% + 7px);
  height: calc(100% + 4px);
}

.win .unitCard .cardWrapper > img {
}

.deckCard .cardWrapper > img {
  transform: translateX(-2px);
  height: calc(100% + 4px);
}

.deckCard-infoCard {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 180%;
  width: 200%;
  background: url(../img/card/cardInfo.png) no-repeat;
  background-size: contain;
  background-position: center;

  transform: translateX(30%) translateY(-23%);
}

#portal .unitCard .cardWrapper > img,
#portal .unitCard .cardWrapper > video {
  width: calc(100% + 5.5px);
}

#portal .deckCard,
#portal .unitCard {
  position: fixed;
  left: 30%;
  top: 30%;
  width: 1000px;
  height: 490px;
  z-index: 190;
  transition: none;
  /*transform: translate(-150%, -82%) rotate(0deg);
  /*animation: cardRightClick 0.7s ease;*/
}

#portal .deckCard-infoCard {
  display: flex;
}

#portal .unitCard .upperText {
  display: none;
}

.deckCard-infoCard-textWrapper {
  height: 50%;
  margin: auto auto auto auto;
  transform: translateX(12%);
  width: 28%;
  /* margin-right: 162px; */
  /* width: 50%; */
  padding: 20px 0 0 0;
  font-family: sans-serif;
  color: rgb(226, 208, 208);
}

.deckCard-infoCard-textWrapper h5 {
  font-weight: 700;
  font-size: 25px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  color: white;
}

.deckCard-infoCard .deckCard-infoCard-textWrapper h3 {
  font-size: 30px;
  font-family: black-rose, sans-serif;
  color: rgb(253, 223, 167);
  margin-bottom: 15px;
}

.deckCard-infoCard-textWrapper q {
  font-style: italic;
  font-size: 16px;
}

.deckCard-infoCard-textWrapper > p {
  font-size: 16px;
  line-height: 22px;
  margin: 12px 0;
  text-align: left;
}

.deckCard-infoCard-ability {
  font-weight: 700;
  font-size: 17px;
  display: flex;
  align-items: center;
}

.deckCard-infoCard-ability > span,
.deckCard-infoCard-stats .level > span,
.deckCard-infoCard-stats .exp > span,
.deckCard-infoCard-rarity > span {
  color: rgb(253, 223, 167);
}

.deckCard-infoCard-stats .exp > span.left {
  color: green;
}

.deckCard-infoCard-ability > b,
.deckCard-infoCard-stats .level > b,
.deckCard-infoCard-stats .exp > b,
.deckCard-infoCard-rarity > b {
  margin: 0 3px;
}

.deckCard-infoCard-SpellPic .cardSpellShutter {
  position: relative;
  transform: unset;
  display: flex;
  width: 35px;
  height: 35px;
  left: unset;
  margin-left: 10px;
}

.deckCard-infoCard-SpellPic .cardSpellShutter > .cardSymbol {
  position: static;
  margin: auto;
  bottom: unset;
  left: unset;
  transform: unset;
  width: 22px;
  height: 22px;
}

.deckCard-infoCard-stats {
  margin: 20px 0;
  display: flex;
  font-size: 18px;
}

.deckCard-infoCard-stats .level {
  margin-right: 20px;
}

.deckCard-infoCard-dimonds {
  display: flex;
  margin-top: 7px;
}

.deckCard-infoCard-dimonds .dimondWrapper {
  margin: 0 2px;
}

.deckCard-infoCard-rarity {
  font-size: 17px;
}

footer {
  padding-top: 40px;
  height: 400px;
  position: relative;
  z-index: 1;
}

footer.visible::before {
  background: url(../img/home/footerGhostBg.png) no-repeat;
  background-size: cover;
  background-position: 0 80%;
  z-index: -1;
}

.footerLinks {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 66%;
  position: absolute;
  right: 0px;
  bottom: -170px;
}

.footerLogo {
  position: absolute;
  left: -130px;
  bottom: -180px;
}

.footerLinks .flexCol {
  align-items: flex-start;
  justify-content: flex-start;
}

.flexCol h3 {
  text-transform: uppercase;
  font-family: albertus-nova-bold, sans-serif;
  font-size: 20px;
  letter-spacing: 4px;
}
.flexCol ul {
  margin-top: 11px;
}

.flexCol ul li {
  font-family: work-sans-medium, sans-serif;
  font-size: 16px;
  opacity: 1;
  letter-spacing: -0.5px;
  padding: 8px 0;
  cursor: pointer;
}

.flexCol ul li p {
  opacity: 0.6;
}

.topFooter {
  position: relative;
}

.bottomFooter {
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 0;
  font-family: work-sans-medium, sans-serif;
}

.bottomFooter span.copyRight,
.bottomFooter .misc {
  opacity: 0.3;
  font-size: 16px;
  letter-spacing: -0.5px;
}

.bottomFooter .misc {
  position: absolute;
  left: 41%;
  bottom: 0px;
}

.bottomFooter span.copyRight {
  /* transform: translateX(-130%); */
  position: absolute;
  bottom: 0px;
  left: 4%;
}

.bottomFooter .misc *:first-child {
  margin-right: 24px;
}

ul.socials {
  display: flex;
  position: absolute;
  right: 90px;
  bottom: -10px;
}

ul.socials li svg g {
  transition: all 0.3s ease;
}

ul.socials li:hover svg g {
  opacity: 0.9;
}

ul.socials li:hover svg path {
  fill: var(--white);
}

ul.socials li:last-child div {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollUp {
  position: fixed;
  display: flex;
  right: 5%;
  bottom: 10%;
  /*transform: translate(150%, -50%);*/
  background: radial-gradient(
    46.87% 46.87% at 50% 50%,
    #000100 0%,
    rgba(0, 1, 0, 0) 100%
  );
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  flex-direction: column;
  align-items: center;
  /*animation: fadein 0.8s ease;*/
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
}

.scrollUp.vis {
  opacity: 1;
  pointer-events: unset;
}

.scrollUp svg {
  transform: rotate(90deg);
  width: 58px;
  height: 58px;
  opacity: 1;
  cursor: pointer;
}

.scrollUp span {
  margin-top: 16px;
  font-size: 16px;
  font-family: work-sans, sans-serif;
  opacity: 0.6;
}

.footerShadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 44px;
  background: linear-gradient(180deg, #000100 0%, rgba(0, 1, 0, 0) 100%);
  opacity: 0.3;
}

.burgerMenu > img {
  position: relative;
  z-index: 99999;
  margin-right: 15px;
}

.burgerMenu .headerMenu {
  display: block;
  position: fixed;
  transform: translateX(-100%);
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: var(--mainBg);
  overflow: hidden;
  transition: all 0.2s ease;
}

.burgerMenu.toggled .headerMenu {
  transform: translateX(0);
}

.burgerMenu.toggled > img {
  position: fixed;
  left: 12px;
  top: 25px;
}

.burgerWrapper {
  display: flex;
  align-items: center;
}

.mobileFeature {
  height: 600px;
  width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transform: translateX(14px);
  margin: auto auto 0 auto;
}

.mobTestimonial {
  height: 500px;
}

.mobileFeature img.woodenPad {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.mobileFeatureWrapper {
  padding-top: 50px;
  display: flex;
  align-items: flex-end;
}

.mobileFeature-pic {
  width: 350px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -80px;
}

.mobileFeature .cardText h3 {
  font-size: 24px;
  line-height: 32px;
}

.mobileFeature .cardText p {
  width: 80%;
  margin: 0 auto 20px auto;
}

.mobileFeature .cardText {
  margin-top: 250px;
  margin-bottom: 30px;
}

.mobTestimonial .cardContainer {
  width: auto;
  margin: 40px auto auto auto;
}

.mobTestimonial .testimonialTemplate h3 {
  font-size: 15px;
}

.mobTestimonial .testimonialTemplate p {
  padding: 0 15px;
  margin-bottom: 40px;
}

.mobTestimonial .testimonialTemplate a {
  font-size: 14px;
}

.deckCard-infoCard-Close {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 20px;
  width: 20px;
  height: 20px;
  background: url(../img/icons/close.svg) no-repeat;
  background-size: cover;
  z-index: 99999;
}

.deckCard-infoCard-Close:hover {
  filter: brightness(87%);
}

.cardContainer .shineContainer {
  width: 90%;
  height: 429px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.cardContainer .shineContainer.activeAnimation .shine::after {
  -webkit-animation: shine 5.75s;
  animation: shine 5.75s;
  display: block;
}

.cardContainer .shineContainer .shine::after {
  width: 10%;
  -webkit-animation: unset;
  animation: unset;
}

.allyCard .shineContainer .shine {
  top: 3%;
  height: 97%;
}

.allyCard .shineContainer .shine::after {
  -webkit-animation: shaped-shine1 2.5s;
  animation: shaped-shine1 2.5s;
}

.welcomePart video {
  margin-bottom: 20px;
  width: 380px;
  background: transparent;
}

.wikiAnim {
  width: 105%;
  height: 105%;
  position: absolute;
  left: -22%;
  top: 0%;
}

#portal .cardWrapper {
  position: absolute;
  left: 0;
  top: 0;
}
/*.........--------------------------...................------------------------------.................*/

.blazeAnimation {
  position: absolute;
  left: 5%;
  width: 800px;
  bottom: 0;
}

.footerLinks ul li a {
  transition: all 0.25s ease;
}

.footerLinks ul li a:hover {
  text-shadow: 7px 4px 15px rgba(156, 124, 27, 0.4),
    -7px -4px 15px rgba(156, 124, 27, 0.4),
    7px -4px 15px rgba(156, 124, 27, 0.4), -7px 4px 15px rgba(156, 124, 27, 0.4),
    7px 4px 15px rgba(156, 124, 27, 0.4), -7px -4px 15px rgba(156, 124, 27, 0.4);
}

.mechanicPoints {
  height: 360px;
  margin-top: 10px;

  font-family: albertus-nova, sans-serif;
  color: var(--ligtGrey);
}

.mechanicPoints .mech.flexCol {
  display: none;
}

.mechanicPoints .mech.flexCol.selected {
  display: flex;
}

.mechPointsContainer > .container {
  position: relative;
  display: flex;
  padding: 0;
  right: 50px;
  width: 100%;
}

.mechPointsContainer .sliderCardButton.fancyArrow.sliderCardButton-prev {
  top: 30%;
  left: -1%;
}

.mechPointsContainer .sliderCardButton.fancyArrow.sliderCardButton-next {
  top: 30%;
  right: -9%;
}

.mechanicPoints.visible {
  background: url(../img/card/wood_paper_frame.png) no-repeat;
  /* background-position: 40% 100%; */
  /* background-size: cover; */
  background-size: 100% 90%;
  min-width: 100%;
  margin-left: 50px;
  position: static;
}

.mechanicPoints h2 {
  margin-top: 7%;
  font-size: 24px;
  margin-bottom: 35px;
  text-align: center;
}

.mechanicPoints p {
  font-family: work-sans;
  width: 85%;
  text-align: center;
  font-size: 21px;
}

/*-------------------------------------------------------------------*/

#mapContainer {
  width: 100%;
  height: 100%;
  background: #b0b0b0;

  overflow: hidden;
}

#mapContainer .close {
  position: absolute;
  right: 2%;
  top: 2%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

#mapContainer .close path {
  fill: black;
}

#mapContainer {
  animation: unwrapMap 0.6s ease-in-out;
}

#myWindow {
  width: 100%;
  height: 100%;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
}

#myContent {
  position: relative;
  display: flex;
  align-items: center;
}

#myContent img {
  display: block;
  width: auto;
  height: auto;
  margin: auto;
  align-self: center;
  flex-shrink: 0;
}
.powerPoints img {
  margin-bottom: 15px;
}

.powerPoints span {
  font-family: work-sans;
  font-size: 20px;
  color: var(--ligtGrey);
}
.powerPoints {
  padding: 80px 0 150px 0;
}

.verification {
  background: url(../img/home/verification_email.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 125px;
  height: 65px;
}

.verify {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
  max-width: 65%;
  margin-left: 163px;
  background-color: #0001008b;
  border-radius: 10px;
}

.signUpContainer {
  background: url(../img/home/sliderCardGolden.png);
  background-size: cover;
  width: 900px;
  height: 490px;
}

.signUpContainer .signUp {
  text-align: center;
  padding: 80px 45px;
  font-family: work-sans;
  user-select: none;
}

.signUpContainer .signUp h2 {
  font-size: 42px;
}

.signUpContainer .signUp p {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.8px;
  padding: 15px 14%;
}

.signUp-buttonsContainer {
  width: 332px;
  margin: 0 auto;
}

.signUp-buttonsContainer button.walletConnect {
  /* background: linear-gradient(269.81deg, #ce7404 8.09%, #ee8400 96.5%); */
  background: url(../img/home/connect_metamask.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 3px;
  width: 100%;
  border: none;
  padding: 13px 10px;
  color: white;
  line-height: 30px;
}

.signUp-buttonsContainer button.walletConnect:hover {
  /* background: linear-gradient(269.81deg, #ce7404 8.09%, #ee8400 96.5%); */
  background: url(../img/home/hovered_button.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 3px;
  width: 100%;
  border: none;
  padding: 13px 10px;
  color: white;
  line-height: 30px;
}

.signUp-buttonsContainer button.signedUp {
  /* background: linear-gradient(269.81deg, #ce7404 8.09%, #ee8400 96.5%); */
  background: url(../img/home/signedUp.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 3px;
  width: 100%;
  border: none;
  padding: 10px 50px;
  color: white;
  line-height: 30px;
}

.signUp-buttonsContainer button.walletConnected {
  /* background: linear-gradient(269.81deg, #873e23 8.09%, #e28743 96.5%); */
  background: url(../img/home/metamask_connected.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 3px;
  width: 100%;
  border: none;
  padding: 13px 10px;
  color: white;
  line-height: 30px;
}

.signUp-buttonsContainer > span {
  margin: 10px 0;
}

button.walletConnect svg {
  margin-right: 10px;
}

button.walletConnected svg {
  margin-right: 10px;
}

.emailForm {
  margin-bottom: 15px;
  width: 100%;
}
.shake {
  animation: shake 0.3s;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  50% { transform: translateX(10px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
}

.emailForm input:not(input[type="checkbox"]) {
  background: rgba(217, 217, 217, 0.15);
  border-radius: 3px;
  border: none;
  padding-left: 10px;
  color: white;
}

.emailForm input:active,
.emailForm input:focus {
  outline: none;
}

.emailForm input::placeholder {
  color: white;
  font-size: 15px;
  padding-left: 10px;
}

.emailForm.flexRow {
  align-items: stretch;
  justify-content: center;
}
.emailForm.flexRow .error_msg {
  color: red;
}

.emailForm button {
  background: url(../img/icons/formBtn.png) no-repeat;
  background-size: cover;
  min-width: 30%;
  min-height: 40px;
  margin-left: -3px;
  color: white;
  border: none;
  font-size: 16px;
  line-height: 20px;
}

.emailForm span {
  font-size: 12px;
  margin-left: -2px;
}

.emailForm input[type="checkbox"] {
  margin-right: 10px;
}
.privacy-policy {
  font-size: 14px;
  color: darkgrey;
}

.privacy-policy a {
  font-size: 14px;
  color: #eaecef;
  text-decoration: underline;
}
.signClose {
  position: absolute;
  right: 3%;
  top: 6%;
  background: url(../img/icons/signClose.png);
  width: 55px;
  height: 55px;
  cursor: pointer;
}

.signClose:active {
  background: url(../img/icons/signClosePushed.png);
}
