.gameMechanicsPage {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: auto;
  height: calc(601px * 1.1);
}

.boardWrapper {
  flex-grow: 1;
  position: relative;
  z-index: 22;
  overflow: hidden;
  border-image: linear-gradient(#f3d478, #9c7c1b) 30;
  border-width: 2px;
  border-style: solid;
}

.battlefieldBoard {
  width: 100%;
  height: 100%;
  position: relative;
  background: var(--mainBg);
}

.ghostTextWrapper {
  margin: auto;
  align-items: center;
}

.ghosts {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.ghosts .ghost {
  position: absolute;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 525;
}

.ghosts .ghost .ghostTextWrapper {
  z-index: 500;
  cursor: default;
}

.ghost h5 {
  text-align: center;
  font-size: 16px;
  color: #faedcd;
  margin-bottom: 5px;
  text-shadow: 14px 7px 9px var(--cardShadow), -14px -7px 9px var(--cardShadow),
    14px -7px 9px var(--cardShadow), -14px 7px 9px var(--cardShadow),
    14px 7px 9px var(--cardShadow), -14px -7px 9px var(--cardShadow);
}

.ghost p {
  font-size: 13px;
  color: #faedcd;
  margin: 0;
  line-height: 20px;
  text-shadow: 14px 7px 9px var(--cardShadow), -14px -7px 9px var(--cardShadow),
    14px -7px 9px var(--cardShadow), -14px 7px 9px var(--cardShadow),
    14px 7px 9px var(--cardShadow), -14px -7px 9px var(--cardShadow);
  text-align: center;
}

.boardBackground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.boardBackground.battlefieldBackground {
  z-index: 21;
  opacity: 1;
}

.boardBackground.battlefieldBackground.loaded {
  background: url(../img/battlefield/Battlefield_background-min.png) no-repeat;
  background-size: contain;
  background-position: center;
}

.boardBackground.Deployable.Cards {
  background: url(../img/battlefield/Deployable_cards_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Deployable.Cards.ghost {
  width: 60%;
  height: 15%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ghosts .Deployable.Cards.ghost p {
  width: 70%;
  margin: auto;
}

.boardBackground.Deck {
  background: url(../img/battlefield/deck_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Deck.ghost {
  width: 10%;
  height: 20%;
  bottom: -3%;
  left: 5%;
  transform: rotate(25deg);
}

.ghosts .Deck.ghost .ghostTextWrapper {
  transform: rotate(-25deg) translateY(-40%);
  width: 150%;
}

.boardBackground.Graveyard {
  background: url(../img/battlefield/graveyard_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Graveyard.ghost {
  width: 12%;
  height: 20%;
  bottom: -3%;
  right: 5%;
  transform: rotate(-20deg);
}

.ghosts .Graveyard.ghost .ghostTextWrapper {
  width: 150%;
  transform: rotate(20deg) translate(-15%, -30%);
}

.boardBackground.Turn.Button.\&.Score {
  background: url(../img/battlefield/turn_button_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Turn.Button.\&.Score.ghost {
  width: 11%;
  height: 17%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.ghosts .Turn.Button.\&.Score.ghost .ghostTextWrapper {
  transform: translate(0, -20%);
  width: 120%;
}

.boardBackground.Leader.Avatar {
  background: url(../img/battlefield/leader_avatar_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Leader.Avatar.ghost {
  width: 7%;
  height: 20%;
  bottom: 20%;
  left: 8%;
}

.ghosts .Leader.Avatar.ghost .ghostTextWrapper {
  transform: translate(-10%, -10%);
  width: 150%;
}

.boardBackground.Artillery.Row {
  background: url(../img/battlefield/Artillery_row_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Artillery.Row.ghost {
  width: 45%;
  height: 60px;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);
}

.boardBackground.Ranged.Row {
  background: url(../img/battlefield/Ranged_row_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Ranged.Row.ghost {
  width: 40%;
  height: 60px;
  left: 50%;
  bottom: 30%;
  transform: translateX(-50%);
}

.boardBackground.Brawler.Row {
  background: url(../img/battlefield/Brawler_row_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Brawler.Row.ghost {
  width: 33%;
  height: 60px;
  left: 50%;
  bottom: 40%;
  transform: translateX(-50%);
}

.boardBackground.Left.Flank {
  background: url(../img/battlefield/flank_row_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Left.Flank.ghost {
  width: 15%;
  height: 40px;
  left: 23%;
  bottom: 40%;
  transform: translateX(-50%);
}

.ghosts .Left.Flank.ghost .ghostTextWrapper {
  transform: translate(-50px, -50px);
  width: 150%;
}

.boardBackground.Right.Flank {
  background: url(../img/battlefield/right_flank_row_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Right.Flank.ghost {
  width: 15%;
  height: 40px;
  right: 11%;
  bottom: 40%;
}

.ghosts .Right.Flank.ghost .ghostTextWrapper {
  transform: translate(-50px, -50px);
  width: 150%;
}

.boardBackground.War.Machine {
  background: url(../img/battlefield/warmachine_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .War.Machine.ghost {
  width: 180px;
  height: 100px;
  right: 5%;
  bottom: 20%;
}

.ghosts .War.Machine.ghost .ghostTextWrapper {
  transform: translate(-50px, 0);
  width: 150%;
}
.Opponent.ghost .ghostTextWrapper p,
.Oppononet.ghost .ghostTextWrapper p {
  display: none;
}

.boardBackground.Opponent.Deployable.Cards {
  background: url(../img/battlefield/Opp_Deployable_cards_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Opponent.Deployable.Cards.ghost {
  width: 60%;
  height: 5%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ghosts .Opponent.Deployable.Cards.ghost .ghostTextWrapper {
  width: 90%;
  margin: auto;
  transform: translateY(20%);
}

.boardBackground.Opponent.Deck {
  background: url(../img/battlefield/Opp_deck_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Opponent.Deck.ghost {
  width: 10%;
  height: 12%;
  top: 8%;
  left: 15%;
  transform: rotate(-25deg);
}

.ghosts .Opponent.Deck.ghost .ghostTextWrapper {
  transform: rotate(25deg) translate(-10%, -5%);
}

.boardBackground.Opponent.War.Machine {
  background: url(../img/battlefield/Opp_warmachine_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Opponent.War.Machine.ghost {
  width: 15%;
  height: 10%;
  left: 4%;
  top: 18%;
}

.boardBackground.Opponent.Graveyard {
  background: url(../img/battlefield/Opp_graveyard_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Opponent.Graveyard.ghost {
  width: 8%;
  height: 12%;
  top: 7%;
  right: 19%;
  transform: rotate(20deg);
}

.ghosts .Opponent.Graveyard.ghost .ghostTextWrapper {
  transform: rotate(-20deg) translate(-15%, -10%);
}

.boardBackground.Opponent.Leader.Avatar {
  background: url(../img/battlefield/Opp_leader_avatar_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Opponent.Leader.Avatar.ghost {
  width: 60px;
  height: 130px;
  top: 40px;
  right: 12%;
  left: unset;
}

.ghosts .Opponent.Leader.Avatar.ghost .ghostTextWrapper {
  transform: translateX(-20px);
}

.boardBackground.Opponent.Artillery.Row {
  background: url(../img/battlefield/Opp_Artillery_row_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Opponent.Artillery.Row.ghost {
  width: 35%;
  height: 60px;
  left: 50%;
  top: 23%;
  transform: translateX(-50%);
}

.ghosts .Opponent.Artillery.Row.ghost .ghostTextWrapper {
  transform: translateY(-200%);
}

.boardBackground.Opponent.Ranged.Row {
  background: url(../img/battlefield/Opp_Ranged_row_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Opponent.Ranged.Row.ghost {
  width: 35%;
  height: 60px;
  left: 50%;
  top: 32%;
  transform: translateX(-50%);
}

.ghosts .Opponent.Ranged.Row.ghost .ghostTextWrapper {
  transform: translateY(-200%);
}

.boardBackground.Opponent.Brawler.Row {
  background: url(../img/battlefield/Opp_Brawler_row_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Opponent.Brawler.Row.ghost {
  width: 27%;
  height: 60px;
  left: 50%;
  top: 40%;
  transform: translateX(-50%);
}

.ghosts .Opponent.Brawler.Row.ghost .ghostTextWrapper {
  transform: translateY(-200%);
}

.boardBackground.Opponent.Left.Flank {
  background: url(../img/battlefield/Opp_L_flank_row_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Opponent.Left.Flank.ghost {
  width: 15%;
  height: 40px;
  left: 22%;
  top: 40%;
}

.ghosts .Opponent.Left.Flank.ghost .ghostTextWrapper {
}

.boardBackground.Opponent.Right.Flank {
  background: url(../img/battlefield/Opp_R_flank_row_outline.png) no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 154;
}

.ghosts .Opponent.Right.Flank.ghost {
  width: 14%;
  height: 40px;
  right: 15%;
  top: 40%;
}

@media only screen and (max-width: 480px) {
  .gameMechanicsPage {
    height: calc(601px * 1.2);
  }

  .ghost p {
    font-size: 12px;
  }
  .ghost h5 {
    font-size: 16px;
  }

  .ghosts .Graveyard.ghost .ghostTextWrapper {
    width: 150%;
    transform: rotate(20deg) translate(-15%, -5%);
  }

  .ghosts .Right.Flank.ghost .ghostTextWrapper {
    transform: translate(-50px, -40%);
    width: 150%;
  }

  .ghosts .Opponent.Right.Flank.ghost .ghostTextWrapper {
    transform: translate(-50px, -200%);
  }

  .ghosts .Turn.Button.\&.Score.ghost .ghostTextWrapper {
    transform: translate(0, -30%);
  }

  .ghosts .Deck.ghost .ghostTextWrapper {
    transform: rotate(-25deg) translate(-15%, -25%);
    width: 180%;
  }

  .ghosts .Left.Flank.ghost:not(.Opponent) .ghostTextWrapper {
    width: 100%;
    transform: translate(20px, -45%);
  }
}
