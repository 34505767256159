.cardSymbol {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 13;
}

.rowSymbol {
  width: 33px;
  height: 33px;
  right: 11px;
  top: 15px;
}

.rowSymbol.Artillery {
  background-image: url(../img/icons/rowSymbols/Artillery\ symbol\ smaller.png);
}

.rowSymbol.Brawler {
  background-image: url(../img/icons/rowSymbols/Melee\ symbol\ smaller.png);
}

.rowSymbol.Ranged {
  background-image: url(../img/icons/rowSymbols/Ranged\ symbol\ smaller.png);
}

.spellSymbol {
  width: 38px;
  height: 38px;
  bottom: -7.7px;
  left: 50%;
  transform: translateX(-44%);
  /*filter: brightness(115%);*/
}

.spellSymbol.Default {
  background-image: url(../img/icons/spellSymbols/Spell\ symbol.png);
}

.spellSymbol.As.One {
  background-image: url(../img/icons/spellSymbols/As\ one\ ability\ symbol.png);
}

.spellSymbol.Blood.Bond {
  background-image: url(../img/icons/spellSymbols/Blood\ bond\ ability\ symbol.png);
}

.spellSymbol.Counter.Intel {
  background-image: url(../img/icons/spellSymbols/Assassin\ ability\ symbol.png);
}

.spellSymbol.Flag.Bearer {
  background-image: url(../img/icons/spellSymbols/Flag\ bearer\ ability\ symbol.png);
}

.spellSymbol.Intimidate {
  background-image: url(../img/icons/spellSymbols/Intimidate\ ability\ symbol.png);
  bottom: -8px;
}

.spellSymbol.Mind.Control {
  background-image: url(../img/icons/spellSymbols/Mind\ control\ ability\ symbol.png);
}

.spellSymbol.Supremacy {
  background-image: url(../img/icons/spellSymbols/Supremacy\ ability\ symbol.png);
}

.spellSymbol.Auxiliary {
  background-image: url(../img/icons/spellSymbols/Auxiliary\ ability\ icon.png);
}

.spellSymbol.Command {
  background-image: url(../img/icons/spellSymbols/Command\ ability\ symbol.png);
}

.spellSymbol.Double.Agent {
  background-image: url(../img/icons/spellSymbols/Double\ agent\ ability\ symbol.png);
}

.spellSymbol.Immunity {
  background-image: url(../img/icons/spellSymbols/Immunity\ ability\ symbol.png);
}

.spellSymbol.Medic {
  background-image: url(../img/icons/spellSymbols/Medic\ ability\ symbol.png);
  bottom: -8px;
}

.spellSymbol.Rally {
  background-image: url(../img/icons/spellSymbols/Rally\ ability\ symbol.png);
}

.spellSymbol.Terrifying {
  background-image: url(../img/icons/spellSymbols/Terrifying\ ability\ symbol.png);
}

.cardBasePower,
.cardLevel {
  position: absolute;
  z-index: 12;
  display: inline-block;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 17px;
  top: 25px;
  color: black;
  font-size: 42px;
  font-family: cabazon, sans-serif;
}

.cardBasePower.spellball.Ephemeral {
  background: url(../img/lists/frames/Conjured/Spell\ balls/Ephemeral_spellball.png);
  background-size: contain;
  background-position: bottom;
}

.cardBasePower.spellball .appContainer.win .cardBasePower {
  top: 19.5px;
}

.cardBasePower.longDigit {
  left: 16px;
  top: 26px;
}

.appContainer.win .cardBasePower.longDigit {
  left: 12px;
  top: 21px;
}

.cardLevel {
  left: unset;
  right: 5px;
  color: #d8c5a1;
  font-size: 18px;
  top: 46px;
}

.cardSpellShutter {
  position: absolute;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  top: 92.5%;
  left: 50%;
  transform: translateX(-47%);

  bottom: -3.5%;
  z-index: 12;
  background-size: cover;
}

.win .cardSpellShutter {
  bottom: -3.6%;
}

#portal .cardSpellShutter {
  bottom: -3.1%;
}

.cardSpellShutter.Simple {
  background-image: url(../img/icons/shutterBg/Simple.png);
}

.cardSpellShutter.Rare {
  background-image: url(../img/icons/shutterBg/Rare.png);
}
.cardSpellShutter.Common {
  background-image: url(../img/icons/shutterBg/Common.png);
}

.cardSpellShutter.Basic {
  background-image: url(../img/icons/shutterBg/Basic.png);
}

.cardSpellShutter.Uncommon {
  background-image: url(../img/icons/shutterBg/Uncommon.png);
  top: 92.3%;
}

.cardSpellShutter.Epic {
  background-image: url(../img/icons/shutterBg/Epic.png);
}

.cardSpellShutter.Legendary {
  background-image: url(../img/icons/shutterBg/Legendary.png);
}

.cardSpellShutter.Mythic {
  background-image: url(../img/icons/shutterBg/Mythic.png);
}

.hoverBlock {
  display: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: albertus-nova;
  color: rgb(221, 204, 181);
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.hoverBlock.safari {
  -webkit-animation: none; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: none; /* Firefox < 16 */
  -ms-animation: none; /* Internet Explorer */
  -o-animation: none; /* Opera < 12.1 */
  animation: none;
}

.hoverBlock.ghostBg {
  background: linear-gradient(
    black 13%,
    transparent 46%,
    transparent 50%,
    black 87%
  );
  z-index: 10;
}

.hoverBlock-text {
  text-align: center;
  width: 80%;
  bottom: 70px;
}

.hoverBlock h5 {
  top: 30px;
  font-size: 23px;
  width: 50%;
  text-align: center;
  transform: translateX(-45%);
  font-family: black-rose, sans-serif;
}

.hoverBlock .hoverBlock-text span {
  font-family: black-rose, sans-serif;
}

.hoverBlock p {
  font-size: 14px;
  margin-top: 10px;
}

.alice-carousel__stage-item.__active:hover .hoverBlock {
  display: block;
}

.cardFlip {
  perspective: 1000px;
}

.cardFlip .cardFlip-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.cardFlip.flipped .cardFlip-inner {
  transform: rotateY(180deg);
}

#portal .cardWrapper .spellSymbol {
  transform: translateX(-44%);
  bottom: -11px;
}

.win .deckCard .cardWrapper > img {
  transform: translateY(3px);
}

.unitCard .videoContainer video,
.deckCard .videoContainer video {
  object-fit: cover;
}
