@-moz-keyframes spin {
  from {
    -webkit-transform: translateX(-50%) rotate(0deg);
    left: 50%;
  }
  to {
    -webkit-transform: translateX(-50%) rotate(360deg);
    left: 50%;
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: translateX(-50%) rotate(0deg);
    left: 50%;
  }
  to {
    -webkit-transform: translateX(-50%) rotate(360deg);
    left: 50%;
  }
}
@keyframes spin {
  from {
    transform: translateX(-50%) rotate(0deg);
    left: 50%;
  }
  to {
    transform: translateX(-50%) rotate(360deg);
    left: 50%;
  }
}

@keyframes spinRight {
  from {
    transform: translateX(-50%) rotate(0deg);
    left: 50%;
  }
  to {
    transform: translateX(-50%) rotate(360deg);
    left: 50%;
  }
}

@keyframes spinLeft {
  from {
    transform: translateX(-50%) rotate(0deg);
    left: 50%;
  }
  to {
    transform: translateX(-50%) rotate(-360deg);
    left: 50%;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*@keyframes popOut {
    from {
      opacity: 0.5;
    }
    to {
      transform: scale(1.05) translateY(-5%);
      z-index: 999;
      opacity: 1;
    }
  }*/

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes cardRightClick {
  from {
    transform: translate(-50%, -30%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -82%);
    opacity: 1;
  }
}

@keyframes cardRightClick1 {
  from {
    transform: translate(-50%, -30%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes unwrapMap {
  0% {
    width: 0%;
    height: 0%;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}

@-webkit-keyframes shine {
  0% {
    left: -80%;
  }

  40% {
    left: 180%;
  }

  100% {
    left: 180%;
    height: 100%;
  }
}

@keyframes shine {
  0% {
    left: -80%;
  }

  30% {
    left: 180%;
  }

  100% {
    left: 180%;
    height: 100%;
  }
}

@-webkit-keyframes shaped-shine1 {
  0% {
    left: -80%;
    height: 0;
  }

  5% {
    height: 15%;
  }

  5% {
    height: 20%;
  }

  14% {
    height: 99%;
  }

  16% {
    height: 94%;
  }

  30% {
    height: 60%;
  }

  40% {
    left: 180%;
    height: 100%;
  }

  100% {
    left: 180%;
    height: 100%;
  }
}
@keyframes shaped-shine1 {
  0% {
    left: -80%;
    height: 0;
  }

  5% {
    height: 15%;
  }

  5% {
    height: 20%;
  }

  14% {
    height: 99%;
  }

  16% {
    height: 94%;
  }

  30% {
    height: 60%;
  }

  40% {
    left: 180%;
    height: 100%;
  }

  100% {
    left: 180%;
    height: 100%;
  }
}

.autoplay {
  animation: playCarousel 200s infinite;
}
